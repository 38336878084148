<template>
    <div class="chat-input">
        <input v-model="text" @keydown.enter="send" placeholder="Type a message..." />
    </div>
</template>

<script>
export default {
    data() {
        return { text: "" };
    },
    methods: {
        send() {
            this.$emit("send", this.text);
            this.text = "";
        },
    },
};
</script>

<style>
.chat-input input {
    width: 100%;
    padding: 10px;
}
</style>