<!-- App.vue -->
<template>
  <div id="app">
    <!-- Navbar na vrhu -->

    <!-- Glavni sadržaj -->
    <div class="main-content">
      <MainNavbar />
      <!-- Sidebar -->
      <MainSidebar />

      <!-- Router View -->
      <div class="main-router">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import MainSidebar from "./components/MainSidebar.vue";
import MainNavbar from "./components/MainNavbar.vue";

export default {
  name: "App",
  components: {
    MainSidebar,
    MainNavbar,
  },
};
</script>



