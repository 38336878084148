// src/store/index.js

import { createStore } from 'vuex';
import vertexAI from './vertexAi';  // Importujte vertexAI modul

const store = createStore({
    modules: {
      vertexAI,  // Registracija modula
    },
  });
  
  export default store;