<template>
  <div class="login-container">
    <!-- Page title -->
    <h1>Login</h1>

    <!-- Google login button -->
    <button class="login-button google" :disabled="!isReady" @click="login">
      <i class="fab fa-google icon"></i> <!-- Google icon -->
      Continue with Google
    </button>

    <!-- Apple login button -->
    <button class="login-button apple">
      <i class="fab fa-apple icon"></i> <!-- Apple icon -->
      Continue with Apple
    </button>

    <!-- Privacy policy at the bottom of the screen -->
    <p class="footer-text">
      By using Moii.Education, you agree to our Terms and have read our Privacy Policy.
    </p>
  </div>
</template>

<script>
import useGoogleSignIn from "@/composables/GoogleSignInComposable"; // Importujte vaš Google login composable

export default {
  name: 'LoginView',
  setup() {
    const { isReady, login } = useGoogleSignIn(); // Pozivamo composable funkciju

    return { isReady, login }; // Vraćamo vrednosti da bismo ih koristili u template-u
  }
};
</script>