<template>
  <div class="home-page container">
    <div class="modal-header">
      <h1 class="modal-title text-light">
        Create New Project
      </h1>
    </div>

    <div class="modal-body">
      <form>
        <div class="mb-3">
          <input type="text" class="form-control bg-dark text-light" placeholder="Title">
        </div>

        <div class="mb-3">
          <select class="form-select bg-dark text-light">
            <option selected disabled>Select an Option</option>
            <option value="option1">Option 1</option> 
            <option value="option2">Option 2</option> 
            <option value="option3">Option 3</option> 
          </select>
        </div>

        <div class="mb-3">
          <textarea class="form-control bg-dark text-light" placeholder="Data" rows="4"></textarea>
        </div>

        <div class="modal-footer">
          <button type="submit" class="btn btn-primary w-100">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>
