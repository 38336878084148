<template>
    <div :class="['message', msg.sender]">{{ msg.text }}</div>
</template>

<script>
export default {
    props: ["msg"],
};
</script>

<style>
.message.user {
    text-align: right;
    color: blue;
}

.message.ai {
    text-align: left;
    color: green;
}
</style>