<template>
    <div class="chat-container">
        <ChatMessage v-for="(msg, index) in messages" :key="index" :msg="msg" />
        <ChatInput />
    </div>
</template>

<script>
import ChatMessage from "./ChatMessage.vue";
import ChatInput from "./ChatInput.vue";

export default {
    components: { ChatMessage, ChatInput },
    methods: {
    },
};
</script>