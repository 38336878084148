<template>
  <nav class="navbar">
    <div class="navbar-content">
      <!-- Ostali elementi navigacijske trake ako su potrebni -->
      <div class="user-menu">
        <img
          src="@/assets/user-avatar.png"
          alt="User Avatar"
          class="user-avatar"
          @click="toggleMenu"
        />
        <div class="dropdown-menu" :class="{ open: showMenu }">
          <ul>
            <li @click="logout">Logout</li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      // Implementirajte funkciju odjave
      console.log('Korisnik se odjavio');
      // Očistite autentifikacijske podatke i preusmjerite na login stranicu
      // this.$store.dispatch('logout');
      // this.$router.push('/login');
    },
  },
};
</script>

