<script>
// GoogleSignInComposable.js
import { useTokenClient } from "vue3-google-signin";

const handleOnSuccess = (response) => {
    console.log("Access Token: ", response.access_token);
};

const handleOnError = (errorResponse) => {
    console.log("Error: ", errorResponse);
};

// Inicijalizacija Google klijenta sa funkcijama uspeha i greške
export default function useGoogleSignIn() {
    const { isReady, login } = useTokenClient({
        onSuccess: handleOnSuccess,
        onError: handleOnError,
        // Ovde dodajte druge opcije ako su potrebne
    });

    return { isReady, login }; // Vraća isReady i login za upotrebu u drugim komponentama
}

</script>
