<template>
    <div>
        <!-- Sidebar -->
        <div :class="['sidebar', { 'sidebar-open': isSidebarOpen }]">
            <!-- Sidebar header with toggle button -->
            <div class="sidebar-header">
                <button @click="toggleSidebar" class="sidebar-toggle-btn">
                    <span class="hamburger-line" :class="{ 'open': isSidebarOpen }"></span>
                    <span class="hamburger-line" :class="{ 'open': isSidebarOpen }"></span>
                    <span class="hamburger-line" :class="{ 'open': isSidebarOpen }"></span>
                </button>
            </div>
            <!-- Sidebar list -->
            <ul class="sidebar-list">
                <li @click="sendRequest('Write a story about a magic backpack.')">
                    <i class="fas fa-folder"></i>
                    <span>{{ projectData ? projectData.name : 'Project 1' }}</span>
                </li>
                <li>
                    <i class="fas fa-folder"></i>
                    <span>Project 2</span>
                </li>
                <li>
                    <i class="fas fa-folder"></i>
                    <span>Project 3</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data() {
        return {
            isSidebarOpen: false,
        };
    },
    computed: {
        // Mapiranje state-a sa Vuex store-a za projectData
        ...mapState(['projectData']),
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },

        sendRequest(model) {
            // Poziv Vuex akcije sa namespaced imenom
            this.$store.dispatch('vertexAI/fetchProjectData', model);  // 'vertexAI/fetchProjectData' je tačan naziv
        },

        // Mapiranje akcije iz Vuex
        ...mapActions(['fetchProjectData']),
    },
};
</script>