<template>
    <ChatContainer />
</template>

<script>
import ChatContainer from "@/components/Chat/ChatContainer.vue";

export default {
    components: { ChatContainer },
};
</script>