import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/dark.css"; // Import global CSS here
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.css";
import GoogleSignInPlugin from "vue3-google-signin";

// Create the app instance
const app = createApp(App);

app.use(GoogleSignInPlugin, {
    clientId: "300673424604-gi7b15lt4i10rqd5s2dn205v2lt5klad.apps.googleusercontent.com",
});

// Use the router and mount the app
app.use(router)
    .use(store)
    .mount("#app");
