import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import HomePage from '../views/HomePage.vue'; 
import ChatView from '../views/ChatView.vue'; 

const routes = [
    {
        path: '/',         
        redirect: '/login' 
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginView,
    },
    {
        path: '/home',  
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/chat',  
        name: 'Chat',
        component: ChatView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
