// src/store/vertexAI.js
import axios from "axios";

export default {
    namespaced: true, // Koristimo namespaced za modularni store

    state: {
        projectData: null,
        error: null,
    },

    mutations: {
        setProjectData(state, data) {
            state.projectData = data;
        },
        setError(state, error) {
            state.error = error;
        },
    },

    actions: {
        async fetchProjectData({ commit }, model) {
            console.log("Fetching project data for model:", model); // Debugging log

            const requestData = {
                model: model,
            };

            try {
                const response = axios
                    .post(
                        "https://mvp.api.moii.education/api/generate-text",
                        requestData
                    )
                    .then(function (response) {
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                commit("setProjectData", response.data); // Spremi podatke u state
            } catch (error) {
                commit("setError", error); // Spremi grešku u state
                console.error("Error while fetching project data:", error);
            }
        },
    },

    getters: {
        projectData: (state) => state.projectData,
        error: (state) => state.error,
    },
};
